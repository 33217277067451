@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
  margin: 0;
  font-family: 'Rubik',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #16161A;
  color:aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bHsVgM :first-child {
  width: 90% !important;
}

.sc-gfbRpc {
  background-color: rgb(33, 33, 38) !important;
  color: white !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.bqsWMK{
  background-color: rgba(33, 33, 38, 0.95) !important;
  border: 0 !important;
  padding: 5px !important;
}

.sc-bczRLJ{
  border-radius: 16px !important;
}