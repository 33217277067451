.tabHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .transactions-container {
    display: flex;
    flex-direction: column;
  }
  
  .transaction-group {
    margin-bottom: 20px;
  }
  
  .date-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .transaction {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #27272a;
    border-radius: 5px;
    margin-bottom: 10px;
    justify-content: space-between;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  .transaction:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .transaction-type {
    width: auto;
    font-weight: bold;
  }
  
  .transaction-details {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
  }
  
  .token-amount {
    font-weight: bold;
  }
  
  .address {
    font-style: italic;
    color: #c3c3c3;
  }

  tbody tr:nth-child(odd) {
    background-color: #3f3f46;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }

  tbody tr:nth-child(even) {
    background-color: #52525b;
  }