.App {
  text-align: left;
  min-height: 100vh;
  margin: 0 auto;
  max-width: 820px;
}

.header {
  height: 80px;
  display: flex;
  justify-content: flex-start;
  background-color: #16161A;
  border-bottom: 1px solid rgb(121, 121, 121);
  align-items: center;
}

.title {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}

.walletInputs {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 25px;
}

.walletButton {
  background-color: #16161A;
  border: 1px solid rgb(121, 121, 121);
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Rubik';
}
.walletButton:hover {
  background-color: #313137;
  transition: 0.1s;
}

.react-autosuggest__input {
  width: 30rem;
  padding: 8px;
  background-color: #29292C;
  border: 0;
  border-radius: 10px;
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.react-autosuggest__input:focus {
  outline: none;
  box-shadow: none;
}

.react-autosuggest__input::-webkit-input-placeholder {
  /* Placeholder styles when input is focused */
  color: #aaa;
}

.react-autosuggest__input:focus::-webkit-input-placeholder {
  /* Placeholder styles when input is not focused */
  color: #aaa;
}

/* Autosuggest suggestions container */
.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #29292C;
  color: white;
  border-radius: 5px;
  margin-top: 4px;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Autosuggest suggestion list */
.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Autosuggest suggestion item */
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 10px;
}

/* Autosuggest highlighted suggestion */
.react-autosuggest__suggestion--highlighted {
  background-color: #555;
  border-radius: 5px;
}

.suggestion-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.starting-content {
  margin-top: 15vw;
  text-align: center;
  padding: 1.5em;
  line-height: 2.1;
  color: #f9f9f9;
  border-radius: 15px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.starting-content h2{
  align-items: center;
  margin: 5px 0;
  font-size: 36px;
  font-weight: bold;
  color: #f9f9f9;
}

.starting-content p{
  font-size: 18px;
  color: #e7e4e4;
}

.content {
  height: 100%;
  margin: 0;
  padding: 25px 0px;
  background:#16161A;
}

.walletInfo {
  display: flex;
  justify-content: space-between;
}

.blockie {
  display: flex;

  justify-content: space-between;
  margin: 10px 0px;
}

.blockie-content {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.wallet-info {
  display: flex;
  flex-direction: column;
}

.wallet-address {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.portfolio h2 {
  margin: 5px 0;
  font-size: 42px;
  font-weight: bold;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.1s ease-in-out forwards;
}

/* selector selector*/

.selector-dropdown {
  position: relative;
  margin-left: auto;
}

.selector-selector {
  background-color: transparent;
  border: none;
  padding: 0;
  color: white;
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selector-text {
  margin-right: 6px;
}

.arrow-icon {
  width: 12px;
  height: 12px;
}

.arrow-icon svg {
  fill: white;
  width: 100%;
  height: 100%;
}

.selector-options {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #29292C;
  color: white;
  border-radius: 5px;
  margin-top: 4px;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
}


.selector-option {
  width: 100%;
  padding: 8px 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selector-option:hover {
  background-color: #555;
  border-radius: 5px;
}

.selector-dropdown:hover .selector-options {
  display: block;
}


.button-content {
  display: flex;
  align-items: center;
}

.selector-text {
  margin-right: 6px;
}

.arrow-icon {
  width: 12px;
  height: 12px;
}

.arrow-icon svg {
  fill: white;
  width: 100%;
  height: 100%;
}

.totalValue {
  width: 350px;
  height: 150px;
  padding: 10px 30px; 
  border-radius: 20px;
  background-color: rgba(33, 33, 38, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tabHeading {
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0px;
  align-items: center;
  display: flex;
  gap: 20px;
}

.filters {
  display: flex;
  gap: 20px;
  margin: 30px 0px;
}

.nftInfo {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 5px;
  background-color: #27272a;
  border-radius: 5px;
  padding: 10px;
}

.nftList {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  flex-wrap: wrap;
}

.tab-select {
  display: flex;
  justify-content: space-between;
}

.chain-selector {
  height: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: #29292C;
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
